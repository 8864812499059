import { Fragment, useRef } from "react";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import get from "lodash.get";
import set from "lodash.set";
import { richCaptions, getRichCaptionType } from "./Audio.helpers";

const StickyPlayer = dynamic(() =>
  import("@washingtonpost/media-components/core/audio/homepage-wrapper").then(
    (m) => m.StickyPlayerWrapper
  )
);

const SUB_TITLE_OVERRIDE = {
  "Google Play": "Google Podcasts"
};

const SubscriptionLinks = ({ subscriptionLinks, audioRef }) => {
  return (
    <div className="pb-xs">
      <span className="gray-dark pr-xxs font-size-tiny">Add to:</span>
      {subscriptionLinks.map((link, i) => {
        const isLast = i + 1 === subscriptionLinks.length;
        return (
          <a
            className="blue pr-xxs font-size-tiny"
            id={link.id}
            target="_blank"
            rel="noopener noreferrer"
            key={link.id}
            href={link.url}
            onClick={() => {
              audioRef.current.dispatchEvent(
                new CustomEvent("subscribe", { detail: link.id })
              );
            }}
          >
            {SUB_TITLE_OVERRIDE[link.text]
              ? SUB_TITLE_OVERRIDE[link.text]
              : link.text}
            {isLast ? "" : ","}
          </a>
        );
      })}
    </div>
  );
};

SubscriptionLinks.propTypes = {
  subscriptionLinks: PropTypes.array,
  audioRef: PropTypes.object
};

const RichCaption = ({ template, link }) => {
  const a = template.split("{link}");
  return (
    <Fragment>
      {a[0]}
      <a
        href={link.url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "var(--link-color)" }}
      >
        {link.text}
      </a>
      {a[1]}
    </Fragment>
  );
};

RichCaption.propTypes = {
  template: PropTypes.string,
  link: PropTypes.shape({
    url: PropTypes.string,
    text: PropTypes.string
  })
};

const setRichCaption = (props) => {
  const key = "stickyPlayerAudioProps.audioArticle.human.caption";
  const richCaption = richCaptions?.[getRichCaptionType(get(props, key))];
  if (richCaption) {
    set(props, key, <RichCaption {...richCaption} />);
  }
};

const Audio = (props) => {
  const { subscriptionLinks, alignment } = props;

  const audioRef = useRef();

  const hasSubLinks = subscriptionLinks && subscriptionLinks.length;
  const className = `${alignment} ${
    hasSubLinks ? "pb-xxs" : "pb-xs"
  } overflow-hidden`;

  setRichCaption(props);

  return (
    <Fragment>
      <div data-qa="audio-slot" className={className}>
        <StickyPlayer stickyPlayerProps={props} />
      </div>
      {hasSubLinks && (
        <div className="pb-xs">
          <span className="gray-dark pr-xxs font-size-tiny">Add to: </span>
          {subscriptionLinks.map((link, i) => {
            const isLast = i + 1 === subscriptionLinks.length;
            return (
              <a
                className="blue pr-xxs font-size-tiny"
                id={link.id}
                target="_blank"
                rel="noopener noreferrer"
                key={link.id}
                href={link.url}
                ref={audioRef}
                onClick={() => {
                  audioRef.current.dispatchEvent(
                    new CustomEvent("subscribe", { detail: link.id })
                  );
                }}
              >
                {SUB_TITLE_OVERRIDE[link.text]
                  ? SUB_TITLE_OVERRIDE[link.text]
                  : link.text}
                {isLast ? "" : ","}
              </a>
            );
          })}
        </div>
      )}
    </Fragment>
  );
};

Audio.propTypes = {
  audioType: PropTypes.string,
  subscriptionLinks: PropTypes.array,
  alignment: PropTypes.string
};

export default Audio;
